@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/Ptrootuimedium.woff2') format('woff2'),
  url('../fonts/Ptrootuimedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/Ptrootuilight.woff2') format('woff2'),
  url('../fonts/Ptrootuilight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/Ptrootui.woff2') format('woff2'),
  url('../fonts/Ptrootui.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'PT Root UI';
  src: url('../fonts/Ptrootuibold.woff2') format('woff2'),
  url('../fonts/Ptrootuibold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}


body {
  font-family: 'PT Root UI';
}
